var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-card",
        [
          _c("vue-dropzone", {
            ref: "myVueDropzone",
            attrs: { id: "dropzone", options: _vm.dropzoneOptions },
            on: {
              "vdropzone-file-added": _vm.uploadFile,
              "vdropzone-files-added": _vm.handleMaxFilesErrorMessage,
              "vdropzone-error": _vm.replaceDefaultErrorMessages,
            },
          }),
          _c(
            "v-row",
            [
              _c("v-switch", {
                staticClass: "ml-7 switch-tfa",
                attrs: { label: "Sobrescrever", inset: "", "hide-details": "" },
                model: {
                  value: _vm.overwriteEnabled,
                  callback: function ($$v) {
                    _vm.overwriteEnabled = $$v
                  },
                  expression: "overwriteEnabled",
                },
              }),
              _c(
                "v-tooltip",
                {
                  attrs: { right: "", "max-width": "500", color: "primary" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function ({ on, attrs }) {
                        return [
                          _c(
                            "v-icon",
                            _vm._g(
                              _vm._b(
                                { staticClass: "ml-3" },
                                "v-icon",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [_vm._v("mdi-help-circle-outline")]
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [_c("span", [_vm._v(_vm._s(_vm.overwriteTooltipMessage))])]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { width: "auto" },
          model: {
            value: _vm.errorDialog,
            callback: function ($$v) {
              _vm.errorDialog = $$v
            },
            expression: "errorDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                { staticClass: "text-h5 white--text error" },
                [
                  _c(
                    "v-icon",
                    { staticClass: "white--text", attrs: { left: "" } },
                    [_vm._v("mdi-alert")]
                  ),
                  _vm._v(" Algo deu errado "),
                ],
                1
              ),
              _c("br"),
              _c("v-card-text", { staticClass: "text-h6" }, [
                _vm._v(" " + _vm._s(_vm.errorMessage) + " "),
                _c("br"),
                _vm._v(" " + _vm._s(_vm.errorMessage2) + " "),
              ]),
              _c(
                "v-card-actions",
                { staticClass: "justify-end" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "secondary lighten-2",
                      attrs: { text: "", id: "IdBtnClose" },
                      on: {
                        click: function ($event) {
                          _vm.errorDialog = false
                        },
                      },
                    },
                    [_vm._v(" fechar ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }