<template>
  <div>
    <v-card>
      <vue-dropzone
        id="dropzone"
        ref="myVueDropzone"
        :options="dropzoneOptions"
        @vdropzone-file-added="uploadFile"
        @vdropzone-files-added="handleMaxFilesErrorMessage"
        @vdropzone-error="replaceDefaultErrorMessages"
      />
      <v-row>
        <v-switch v-model="overwriteEnabled" label="Sobrescrever" class="ml-7 switch-tfa" inset hide-details></v-switch>
        <v-tooltip right max-width="500" color="primary">
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" class="ml-3" v-on="on">mdi-help-circle-outline</v-icon>
          </template>
          <span>{{ overwriteTooltipMessage }}</span>
        </v-tooltip>
      </v-row>
    </v-card>

    <v-dialog v-model="errorDialog" width="auto">
      <v-card>
        <v-card-title class="text-h5 white--text error">
          <v-icon left class="white--text">mdi-alert</v-icon>
          Algo deu errado
        </v-card-title>
        <br />
        <v-card-text class="text-h6">
          {{ errorMessage }}
          <br/>
          {{ errorMessage2 }}
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn text class="secondary lighten-2" @click="errorDialog = false" id='IdBtnClose'> fechar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import 'vue2-dropzone/dist/vue2Dropzone.min.css';

import gedService from '@/services/gedService';
import pisCofinsService from '@/services/pisCofinsService.js';
import UserService from '@/services/userService';
import vue2Dropzone from 'vue2-dropzone';

export default {
  name: 'UploadArquivoZipPisCofins',

  components: {
    vueDropzone: vue2Dropzone,
  },

  data() {
    const maxFilesize = 5; // GB
    const maxFiles = 5;
    return {
      overwriteEnabled: false,
      overwriteTooltipMessage:
        'Selecione esta opção para sobrescrever e descartar o último documento processado com a mesma competência e data de emissão.',
      maxFilesize: maxFilesize,
      maxFiles: maxFiles,
      dropzoneOptions: {
        autoProcessQueue: true,
        url: '#',  // O upload do arquivo binário para a URL padrão do componente não pode ser desativado, causando erro 404.
                   // O erro pode ser ignorado já que o upload é feito fora do componente, na função uploadFile()
        acceptedFiles: 'application/x-zip-compressed',
        maxFilesize: maxFilesize * 1024, // MB
        maxFiles: maxFiles,
        thumbnailWidth: 150,  // px
        thumbnailHeight: 150,
        addRemoveLinks: true,
        dictDefaultMessage: `<i class='mdi mdi-cloud-upload' style='font-size: 3em;'><br/></i>Clique ou arraste e solte seus arquivos aqui para enviá-los!<br>Limite: ${maxFiles} arquivos simultâneos com no máximo ${maxFilesize} GB cada`,
      },
      errorDialog: false,
      errorMessage: '',
      errorMessage2: '',
    };
  },

  computed: {
    userInfo: async function () {
      let { data } = await UserService.getUser();

      if (data) {
        return {
          id: data.id,
          username: data.username,
          emailAddress: data.emailAddress,
          authorities: data.authorities,
        };
      }
    },
  },

  methods: {
    async uploadFile(file) {
      let base64ForUpload = await this.getFile(file);

      if (!file.accepted) {
        this.$refs.myVueDropzone.removeFile(file);
      } else {
        const userInfo = await this.userInfo;
        base64ForUpload = base64ForUpload.split(',')[1];
        let fileToUpload = {
          name: file.name.toLowerCase().replace(/[^\w\d\.]/g, "_"),
          user: userInfo.username,
          userid: userInfo.id,
          visibility: false,
          base64: base64ForUpload,
        };
        let gedResponse = await gedService.files.post(fileToUpload);
        let parseResponse
        if (gedResponse.status === 200) {
          let request = {
            ged_ids: [gedResponse.data],
          };
          parseResponse = await pisCofinsService.parse.post(request, this.overwriteEnabled);
          if (parseResponse.status === 201) {
            setTimeout(() => this.$refs.myVueDropzone.removeFile(file), 1000);
            this.$emit('file-upload');
    
          }
        }
        if (gedResponse.status !== 200 || parseResponse.status !== 201) {
          this.errorMessage = `Tente novamente mais tarde ou entre em contato com o administrador`
          this.errorMessage2 = ``
          this.errorDialog = true
        }
      }
    },

    async getFile(file) {
      return await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },

    handleMaxFilesErrorMessage(files) {
      if (files.length > this.maxFiles) {
        const excess = files.length - this.maxFiles

        this.errorMessage = `Você selecionou mais de ${this.maxFiles} arquivos`
        if (excess === 1) {
          this.errorMessage2 = `1 arquivo não pôde ser enviado`
        } else {
          this.errorMessage2 = `${excess} arquivos não puderam ser enviados`
        }

        this.errorDialog = true
      }
    },

    replaceDefaultErrorMessages(file, message, xhr) {
      const element = document.querySelector('.dz-error-message');
      element.style.display = "none"

      if (message.startsWith(`File is too big`)) {
        this.errorMessage = `Falha no envio`
        this.errorMessage2 = `O arquivo deve ter menos de ${this.maxFilesize}GB`
        this.errorDialog = true

      } else if (message === `You can't upload files of this type.`) {
        this.errorMessage = `Falha no envio`
        this.errorMessage2 = `O formato do arquivo deve ser ".zip"`
        this.errorDialog = true
      }
    },
  },
};
</script>
<style scoped>
@import url('https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css');

.switch-tfa >>> .v-input--switch__thumb.primary--text {
  color: white !important;
}

.switch-tfa >>> .v-input--switch__track{
    opacity: 100 !important;
}
</style>
