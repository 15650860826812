import router from '@/router/index.js';
import { axiosPisCofins } from '@/services/config.js';

axiosPisCofins.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    
    if (error.response.status === 401) {
      localStorage.removeItem('access_Token');
      localStorage.removeItem('refresh_Token');
      localStorage.setItem('Error', 'Token vencido, por favor fazer login novamente.');
      router.push('/');
    }
    return Promise.reject(error);
  },
);

export default {
  file_process: {
    get: (id) => {
      return id;
    },
  },
  file_process_detail: {
    get: (id) => {
      return id;
    },
  },
  file_process_detail_list: {
    getAll: () => {
      return axiosPisCofins.get(`fileprocess/detaillist/`);
    },
  },
  parse: {
    post: async (request, overwrite) => {
      return axiosPisCofins.post(overwrite ? `parse/textdocuments/overwrite/` : `parse/textdocuments/`, request);
    },
  },
  sped_fiscal: {
    get: (id) => {
      return id;
    },
  },
  efd_contribuicoes: {
    get: () => {
      return axiosPisCofins.get(`efdctrb/reports/listtocsv/`);
    },
  },
  reports_process: {
    post: (url, body) => {
      return axiosPisCofins.post(`reports/${url}`, body);
    },
    get: (id) => {
      return id;
    },
    getAll: () => {
      return axiosPisCofins.get(`reportsprocess/`);
    },
  },
};
